import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["emailText", "infoText", "emailForm", "emailField"];

  updateText(event) {
    event.preventDefault();
    this.emailTextTarget.textContent = "¡Gracias!";
    this.infoTextTarget.textContent = "No olvides chequear tus mails.";
    this.emailFormTarget.style.display = "none";
    const email = this.emailFieldTarget.value;
    // Realizar una solicitud Fetch POST
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch("/incentives/send_email", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json", // Ajusta los encabezados según sea necesario
      },
      body: JSON.stringify({ email }),
    });
  }
}
